body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@-webkit-keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn)
  }
}

@keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg)
  }

  to {
      -webkit-transform: rotate(1turn);
      transform: rotate(1turn)
  }
}

.spinner-good {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: pulse8 1.1s infinite ease;
  animation: pulse8 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  filter: invert();
}

.spinner-bad {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: spin 1.1s infinite linear;
  animation: spin 1.1s infinite linear;
  box-shadow: 0em -2.6em 0em 0em #ffffff,
  1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
  2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
  1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
  0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
  -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
  -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
  -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  filter: invert();
}


@-webkit-keyframes pulse8 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
    1.8em -1.8em 0 0em #ffffff,
    2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7),
    2.5em 0em 0 0em #ffffff,
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.7),
    1.75em 1.75em 0 0em #ffffff,
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7),
    0em 2.5em 0 0em #ffffff,
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.7),
    -1.8em 1.8em 0 0em #ffffff,
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7),
    -2.6em 0em 0 0em #ffffff,
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.7),
    -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes pulse8 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
    1.8em -1.8em 0 0em #ffffff,
    2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7),
    2.5em 0em 0 0em #ffffff,
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.7),
    1.75em 1.75em 0 0em #ffffff,
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7),
    0em 2.5em 0 0em #ffffff,
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.7),
    -1.8em 1.8em 0 0em #ffffff,
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7),
    -2.6em 0em 0 0em #ffffff,
    -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
    1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
    2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
    1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
    0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
    -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
    -2.6em 0em 0 0em rgba(255, 255, 255, 0.7),
    -1.8em -1.8em 0 0em #ffffff;
  }
}